@import '../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorWhite);
}
.content {
  width: calc(100% - 48px);
  margin: 12px 24px 24px 24px;

  @media (--viewportMedium) {
    max-width: 565px;
    margin: 55px auto 56px auto;
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
}

.heading {
  margin-right: 24px;
}

.submitButton {
  margin-top: 24px;
  margin-bottom: 56px;
}

.qrCode {
  height: auto;
  width: 50%;
}

.labelGreen {
  color:green;
  font-weight: var(--fontWeightBold);
  font-size: 16px;
  line-height: 12px;
}

.labelRed {
  color: red;
}

.labelList {
  font-weight: var(--fontWeightBold);
  font-size: 16px;
  line-height: 12px;
}

.row {
  display: flex;
}

/* Create two equal columns that sits next to each other */
.column { 
  display: inline-block; 
  width: 50%; 
  white-space: normal; 
}

.column70 { 
  display: inline-block; 
  width: 70%; 
  white-space: normal; 
}

.column30 { 
  display: inline-block; 
  width: 30%; 
  white-space: normal; 
}